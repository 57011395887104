@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap");

* {
  padding: 0;
  margin: 0;
}

html,
body {
  font-family: gilroy-normal !important;
  overflow-x: hidden !important;
}
@font-face {
  font-family: gilroy-light;
  src: url("../src//assets/fonts/Gilroy-Light.ttf");
}

@font-face {
  font-family: gilroy-normal;
  src: url("../src//assets/fonts/Gilroy-Regular.ttf");
}

@font-face {
  font-family: gilroy-medium;
  src: url("../src//assets/fonts/Gilroy-Medium.ttf");
}

@font-face {
  font-family: gilroy-semibold;
  src: url("../src//assets/fonts/Gilroy-SemiBold.ttf");
}

@font-face {
  font-family: gilroy-bold;
  src: url("../src//assets/fonts/Gilroy-Bold.ttf");
}

@font-face {
  font-family: gilroy-extrabold;
  src: url("../src//assets/fonts/Gilroy-ExtraBold.ttf");
}

/*FONT FAMILY CLASSES HERE*/
.open_sans {
  font-family: "Open Sans", sans-serif;
}

.ff_gilroy_normal {
  font-family: gilroy-normal;
}

.ff_gilroy_light {
  font-family: gilroy-light;
}

.ff_gilroy_medium {
  font-family: gilroy-medium;
}

.ff_gilroy_semibold {
  font-family: gilroy-semibold;
}

.ff_gilroy_bold {
  font-family: gilroy-bold;
}

.ff_gilroy_extrabold {
  font-family: gilroy-extrabold;
}

:root {
  /* FONT SIZES  */
  --fs-xs: 14px;
  --fs-sm: 16px;
  --fs-md: 20px;
  --fs-lg: 24px;
  --fs-30: 30px;
  --fs-35: 35px;
  --fs-xl: 40px;
  --fs-xxl: 48px;
  --fs-2xl: 64px;
  --fs-3xl: 72px;
  /* COLORS  */
  --white: #ffffff;
  --black: #000000;
  --blue: #001337;
  --light-blue: #032657;
  --extra-light-blue: #0a6fff;
  --light-black: #d9d9d933;
  --dark-blue: #1a2b4b;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* ----------------------------------- ROOTS-MEDIA-QUERIES ----------------------------------- */
@media (max-width: 1399.98px) {
  :root {
    --fs-lg: 22px;
    --fs-xl: 38px;
  }
}

@media (max-width: 1199.98px) {
  :root {
    --fs-lg: 20px;
    --fs-xl: 36px;
    --fs-xxl: 45px;
    --fs-2xl: 54px;
    --fs-3xl: 65px;
  }
}

@media (max-width: 991.98px) {
  :root {
    /* --font-lg: 20px; */
    --fs-xl: 32px;
    --fs-xxl: 40px;
    --fs-2xl: 45px;
    --fs-3xl: 58px;
    --fs-30: 28px;
    --fs-35: 30px;
  }
}

@media (max-width: 767.98px) {
  :root {
    /* --font-lg: 20px; */
    --fs-xl: 28px;
    --fs-xxl: 35px;
    --fs-2xl: 40px;
    --fs-3xl: 50px;
    --fs-30: 25px;
    --fs-35: 26px;
  }
}

@media (max-width: 575.98px) {
  :root {
    --fs-sm: 14px;
    --fs-md: 16px;
    --fs-lg: 16px;
    --fs-xl: 26px;
    --fs-30: 24px;
    --fs-xxl: 30px;
    --fs-2xl: 35px;
    --fs-3xl: 38px;
  }
}
@media (max-width: 374.98px) {
  :root {
    --fs-lg: 14px;
  }
}

/*FONT WEIGHT CLASSES HERE*/

.fw_500 {
  font-weight: 500 !important;
}

.fw_600 {
  font-weight: 600 !important;
}

.fw_800 {
  font-weight: 800 !important;
}

/*FONT SIZES CLASSES HERE*/
.fs_xs {
  font-size: var(--fs-xs) !important;
}
.fs_sm {
  font-size: var(--fs-sm) !important;
}

.fs_md {
  font-size: var(--fs-md) !important;
}

.fs_lg {
  font-size: var(--fs-lg) !important;
}
.fs_30 {
  font-size: var(--fs-30) !important;
}
.fs_35 {
  font-size: var(--fs-35) !important;
}

.fs_xl {
  font-size: var(--fs-xl) !important;
}

.fs_xxl {
  font-size: var(--fs-xxl) !important;
}

.fs_2xl {
  font-size: var(--fs-2xl) !important;
}
.fs_3xl {
  font-size: var(--fs-3xl) !important;
}

/*COLORS CLASSES HERE*/
.color_black {
  color: var(--black) !important;
}

.color_blue {
  color: var(--blue) !important;
}

.color_light_blue {
  color: var(--light-blue) !important;
}
.color_extra_light_blue {
  color: var(--extra-light-blue) !important;
}

.color_white {
  color: var(--white) !important;
}

.bg_black {
  background-color: var(--black) !important;
}

.bg_blue {
  background-color: var(--blue) !important;
}

.bg_light_blue {
  background-color: var(--light-blue) !important;
}

.bg_white {
  background-color: var(--white) !important;
}
/* ============= HERO =================== */
.industry_hero_bg_img {
  background-image: url("./assets/images/png/industry-hero-img.png");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 1070px;
  border-radius: 0px 0px 61px 61px;
  background-attachment: fixed;
  background-position-x: center;
}
.industry_hero_drop_bg {
  background: linear-gradient(
    180deg,
    rgba(217, 217, 217, 0.235) 0%,
    rgba(255, 10, 10, 0) 100%
  );
  border-radius: 33.3483px;
  padding: 21px 25px 21px 29px;
}
.industry_hero_drop_emea_padding {
  padding: 16px 45px 12px 22px !important;
}
.industry_hero_drop_america_padding {
  padding: 16px 120px 22px 22px !important;
}
.industry_hero_drop_latam_padding {
  padding: 21px 25px 27px 22px !important;
}
.industry_hero_drop_oceania_padding {
  padding: 16px 133px 24px 22px !important;
}
.industry_hero_drop_carribean_padding {
  padding: 17px 73px 30px 22px !important;
}
.box_scale_hover {
  transition: all 0.3s ease-in-out;
}
.box_scale_hover:hover {
  transform: scale(1.1) !important;
}
/* ================ INDUSTRIES ================= */
.industries_bg {
  margin-top: -100px;
  background: linear-gradient(258.62deg, #de2d62 1.64%, #186bf5 91.39%), #001337;
  border-radius: 80px !important;
  max-width: 1280px !important;
  padding: 30px 40px;
}
.industries_help_img {
  height: 100% !important;
  transform: translateY(11%);
}
.industry_box_line {
  left: 48%;
  top: 54%;
}
.industries_li_box {
  background: linear-gradient(
    180deg,
    rgba(217, 217, 217, 0.235) 0%,
    rgba(255, 10, 10, 0) 100%
  );
  border-radius: 33.3483px;
  padding: 14.52px 0 14.52px 40px;
}
.height_indudtries_img {
  height: 90%;
}
/* ================ PROJECT JOURNEY INDUSTRY ================= */
.bg_project_journey {
  background: linear-gradient(258.62deg, #2c53c1 1.64%, #5815b1 91.39%), #001337;
}
.industry_project_bg {
  background-image: url("./assets/images/png/indusries-project-journey.png");
  background-size: cover;
  background-position: center;
  min-height: 250px;
  border-radius: 80px !important;
}
/* ================ CORE ================= */
.industry_core_bg {
  background-image: url("./assets/images/png/industry-core-img.png");
  background-size: cover;
  background-position: center;
  min-height: 250px;
}
.industry_core_gradient {
  background: linear-gradient(259.55deg, #ee8063 10.27%, #6d077a 102.76%),
    linear-gradient(258.62deg, #2c53c1 1.64%, #5815b1 91.39%), #001337;
}
/* ================ STREAMLINE TAB ================= */
.streamline_bg_gradient {
  background: linear-gradient(256.84deg, #38bde9 15.36%, #202b8d 86.04%),
    linear-gradient(259.55deg, #ee8063 10.27%, #6d077a 102.76%),
    linear-gradient(258.62deg, #2c53c1 1.64%, #5815b1 91.39%), #001337;
}
.industries_streamline_box {
  padding: 71px 73px;
}
.indutry_stream_line_bg_img {
  background-image: url("./assets/images/png/industry-streamline-bg-img.png");
  background-size: cover;
  background-position: center;
  min-height: 358px;
}
.industry_solution_tabs {
  padding: 9px 81px !important;
  background: rgba(170, 170, 170, 0.51);
  border-radius: 20px;
}
.nav-link {
  margin-left: 0 !important;
  padding: 0 !important;
}

/* .nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border: 1px solid transparent !important;
  outline: unset !important;
} */
.industry_streamline_btn button {
  width: 250px;
}
.industry_overview {
  background: linear-gradient(
    180deg,
    rgba(217, 217, 217, 0.235) 0%,
    rgba(255, 10, 10, 0) 100%
  );
  border-radius: 33.3483px;
  padding: 29px;
}
.overview_devlepment_max_w {
  max-width: 772px;
}
.bussiness_devlepment_img_positioning {
  bottom: 0;
  right: 20%;
}
.overview_cost_max_w {
  max-width: 650px;
}
.overview_project_max_w {
  max-width: 710px;
}
/* ================ SOLUTION TAB ================= */
.industries_solution_box {
  padding: 59px 67px;
}
.indutry_solution_bg_img {
  background-image: url("./assets/images/png/industry-solution-bg-img.png");
  background-size: cover;
  background-position: center;
  min-height: 429px;
}
.ethnography_max_w {
  max-width: 300px;
}
.mystery_max_w {
  max-width: 300px;
}
.industry_requirment {
  background: linear-gradient(
    180deg,
    rgba(217, 217, 217, 0.235) 0%,
    rgba(255, 10, 10, 0) 100%
  );
  border-radius: 33.3483px;
  padding: 20px 27px;
}
.industry_solution_btn button {
  width: unset;
}
.requirment_img_positioning {
  bottom: 0;
  right: 30%;
}
.rounded_border_solution {
  border-radius: 99.86px !important;
}
.transfrom_translate_X_solution_img2 {
  transform: translateX(-0.9px);
}
.transfrom_translate_X_solution_img3 {
  transform: translateX(-1.2px);
}
.transfrom_translate_X_solution_img4 {
  transform: translateX(-1.2px);
}
.transfrom_translate_X_solution_img5 {
  transform: translateX(-1.8px);
}
.transfrom_translate_X_solution_img6 {
  transform: translateX(-3px);
}
.scale_animation {
  transition: all 0.3s linear;
}
.scale_animation:hover {
  transform: scale(1.1);
}
.solution_images_shadow {
  transition: all 0.3s ease-in-out;
}
.solution_images_shadow:hover {
  filter: drop-shadow(0px 4px 23px rgba(0, 0, 0, 0.19));
}
/* ================ PRJOECT JOURNEY ================= */
.padding_top_bottom_project_journey {
  padding-top: 65px;
  padding-bottom: 81px;
}
/* ================ MEDIA QUERY ================= */
@media (max-width: 1399.98px) {
  .overview_devlepment_max_w {
    max-width: 562px;
  }
  .overview_cost_max_w {
    max-width: 564px;
  }
  .overview_project_max_w {
    max-width: 530px;
  }
  .bussiness_devlepment_img_positioning {
    bottom: 0;
    right: 25%;
  }
  .ethnography_max_w {
    max-width: 264px;
  }
  .mystery_max_w {
    max-width: 246px;
  }
  .nav-tabs .nav-link {
    font-size: 30px !important;
  }
  .industry_solution_tabs {
    padding: 9.5px 80px 9.5px 81px !important;
  }
  .industry_streamline_btn button {
    width: unset;
  }
  .industry_solution_btn button {
    width: unset;
  }
}
@media (max-width: 1199.98px) {
  .industries_bg {
    max-width: 960px !important;
    margin-top: -122px !important;
  }
  .overview_devlepment_max_w {
    max-width: 470px;
  }
  .overview_cost_max_w {
    max-width: 502px;
  }
  .bussiness_devlepment_img_positioning {
    bottom: 0;
    right: 28%;
  }
  .overview_project_max_w {
    max-width: 470px;
  }
  .ethnography_max_w {
    max-width: 300px;
  }
  .mystery_max_w {
    max-width: 300px;
  }
  .requirment_img_positioning {
    bottom: 0;
    right: 20%;
  }
  .industry_box_line {
    left: 40%;
    top: 54%;
  }
  .industry_solution_tabs {
    padding: 9.5px 30px !important;
  }
  .industry_hero_drop_emea_padding {
    padding: 16px 10px 12px 22px !important;
  }
  .industry_hero_drop_america_padding {
    padding: 16px 10px 22px 22px !important;
  }
  .industry_hero_drop_latam_padding {
    padding: 21px 10px 27px 22px !important;
  }
  .industry_hero_drop_oceania_padding {
    padding: 16px 10px 24px 22px !important;
  }
  .industry_hero_drop_carribean_padding {
    padding: 17px 10px 30px 22px !important;
  }
  .industry_hero_bg_img {
    min-height: 1000px;
  }
}
@media (max-width: 991.98px) {
  .industry_hero_bg_img {
    background-attachment: unset;
    background-image: url("./assets/images/png/industry-hero-mobile-img.png") !important;
  }
  .industries_bg {
    border-radius: 40px !important;
    max-width: 720px !important;
    transform: translate(0%, 196px) !important;
    margin-top: -46% !important;
  }
  .industries_help_img {
    height: none !important;
    transform: translateY(4%) !important;
  }

  .industry_overview {
    background: none;
    border-radius: none;
    padding: unset;
  }
  .industry_overview_box {
    background: linear-gradient(
      180deg,
      rgba(217, 217, 217, 0.235) 0%,
      rgba(255, 10, 10, 0) 100%
    );
    border-radius: 33.3483px;
    padding: 10px;
  }
  .industries_streamline_box {
    padding: 51px 53px;
  }
  .requirment_img_positioning {
    bottom: 0;
    right: 25%;
  }
  .height_indudtries_img {
    height: 100%;
  }
  .industry_solution_tabs {
    padding: 9.5px 10px !important;
  }
  .nav-tabs .nav-link {
    font-size: 20px !important;
  }

  .industries_solution_box {
    padding: 50px 55px;
  }
  .padding_top_bottom_project_journey {
    padding-top: 50px;
    padding-bottom: 61px;
  }
}
@media (max-width: 767.98px) {
  .industry_hero_bg_img {
    min-height: 1090px !important;
  }
  .industries_bg {
    border-radius: 40px !important;
    transform: translate(0%, 40px) !important;
    margin-top: -25% !important;
    padding: 20px 20px;
  }
  .industry_project_bg {
    border-radius: 30px !important;
  }
  .industries_streamline_box {
    padding: 31px 33px;
  }
  .indutry_stream_line_bg_img {
    min-height: 300px !important;
  }
  .indutry_solution_bg_img {
    min-height: 300px !important;
  }
  .industry_requirment_box {
    background: linear-gradient(
      180deg,
      rgba(217, 217, 217, 0.235) 0%,
      rgba(255, 10, 10, 0) 100%
    );
    border-radius: 33.3483px;
    padding: 15px;
  }
  .industry_requirment {
    background: unset !important;
    border-radius: unset !important;
    padding: unset !important;
  }
  .industry_solution_tabs {
    padding: 9.5px 0px !important;
  }
  .nav-tabs .nav-link {
    font-size: 16px !important;
    border: 0 !important;
  }

  .industries_solution_box {
    padding: 40px 40px;
  }
  .padding_top_bottom_project_journey {
    padding-top: 40px;
    padding-bottom: 50px;
  }
}
@media (max-width: 575.98px) {
  .industry_hero_bg_img {
    min-height: 1000px !important;
    border-radius: 0px 0px 20px 20px;
  }
  .industries_bg {
    margin: 0 auto;
    margin-top: -40% !important;
  }
  .industry_project_bg {
    background-position: 20%;
  }
  .industries_streamline_box {
    padding: 21px 23px;
  }
  .industries_solution_box {
    padding: 21px 23px;
  }
  .industry_solution_tabs {
    padding: 9.5px 5px !important;
  }
}
@media (max-width: 424.98px) {
  .industries_bg {
    margin-top: -32% !important;
  }
}
@media (max-width: 576px) {
  .container,
  .container-sm {
    max-width: unset;
  }
}
