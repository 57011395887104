@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap");

* {
  padding: 0;
  margin: 0;
}

html,
body {
  font-family: gilroy-normal !important;
  overflow-x: hidden !important;
}
@font-face {
  font-family: gilroy-light;
  src: url("../src//assets/fonts/Gilroy-Light.ttf");
}

@font-face {
  font-family: gilroy-normal;
  src: url("../src//assets/fonts/Gilroy-Regular.ttf");
}

@font-face {
  font-family: gilroy-medium;
  src: url("../src//assets/fonts/Gilroy-Medium.ttf");
}

@font-face {
  font-family: gilroy-semibold;
  src: url("../src//assets/fonts/Gilroy-SemiBold.ttf");
}

@font-face {
  font-family: gilroy-bold;
  src: url("../src//assets/fonts/Gilroy-Bold.ttf");
}

@font-face {
  font-family: gilroy-extrabold;
  src: url("../src//assets/fonts/Gilroy-ExtraBold.ttf");
}

/*FONT FAMILY CLASSES HERE*/
.open_sans {
  font-family: "Open Sans", sans-serif;
}

.ff_gilroy_normal {
  font-family: gilroy-normal;
}

.ff_gilroy_light {
  font-family: gilroy-light;
}

.ff_gilroy_medium {
  font-family: gilroy-medium;
}

.ff_gilroy_semibold {
  font-family: gilroy-semibold;
}

.ff_gilroy_bold {
  font-family: gilroy-bold;
}

.ff_gilroy_extrabold {
  font-family: gilroy-extrabold;
}

:root {
  /* FONT SIZES  */
  --font-xs: 14px;
  --font-sm: 16px;
  --font-md: 20px;
  --font-lg: 24px;
  --font-30: 30px;
  --font-35: 35px;
  --font-xl: 40px;
  --font-xxl: 48px;
  --font-2xl: 64px;
  --font-3xl: 72px;
  /* COLORS  */
  --white: #ffffff;
  --black: #000000;
  --blue: #001337;
  --light-blue: #032657;
  --extra-light-blue: #0a6fff;
  --light-black: #d9d9d933;
  --dark-blue: #1a2b4b;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* ----------------------------------- ROOTS-MEDIA-QUERIES ----------------------------------- */
@media (max-width: 1399.98px) {
  :root {
    --font-lg: 22px;
    --font-xl: 38px;
  }
}

@media (max-width: 1199.98px) {
  :root {
    --font-lg: 20px;
    --font-xl: 36px;
    --font-xxl: 45px;
    --font-2xl: 54px;
    --font-3xl: 65px;
  }
}

@media (max-width: 991.98px) {
  :root {
    /* --font-lg: 20px; */
    --font-xl: 32px;
    --font-xxl: 40px;
    --font-2xl: 45px;
    --font-3xl: 58px;
    --font-30: 28px;
    --font-35: 30px;
  }
}

@media (max-width: 767.98px) {
  :root {
    /* --font-lg: 20px; */
    --font-xl: 28px;
    --font-xxl: 35px;
    --font-2xl: 40px;
    --font-3xl: 50px;
    --font-30: 25px;
    --font-35: 26px;
  }
}

@media (max-width: 575.98px) {
  .popular_card_content2 {
    min-height: 300px !important;
    /* max-height: 410.2px; */
  }

  :root {
    --font-sm: 14px;
    --font-md: 16px;
    --font-lg: 18px;
    --font-xl: 26px;
    --font-30: 24px;
    --font-xxl: 30px;
    --font-2xl: 35px;
    --font-3xl: 38px;
  }
}
@media (max-width: 374.98px) {
  :root {
    --font-lg: 14px;
  }
}

/*FONT WEIGHT CLASSES HERE*/

.fw_500 {
  font-weight: 500 !important;
}

.fw_600 {
  font-weight: 600 !important;
}

.fw_800 {
  font-weight: 800 !important;
}

/*FONT SIZES CLASSES HERE*/
.font_xs {
  font-size: var(--font-xs) !important;
}
.font_sm {
  font-size: var(--font-sm) !important;
}

.font_md {
  font-size: var(--font-md) !important;
}

.font_lg {
  font-size: var(--font-lg) !important;
}
.font_30 {
  font-size: var(--font-30) !important;
}
.font_35 {
  font-size: var(--font-35) !important;
}

.font_xl {
  font-size: var(--font-xl) !important;
}

.font_xxl {
  font-size: var(--font-xxl) !important;
}

.font_2xl {
  font-size: var(--font-2xl) !important;
}
.font_3xl {
  font-size: var(--font-3xl) !important;
}

/*COLORS CLASSES HERE*/
.color_black {
  color: var(--black) !important;
}

.color_blue {
  color: var(--blue) !important;
}

.color_light_blue {
  color: var(--light-blue) !important;
}
.color_extra_light_blue {
  color: var(--extra-light-blue) !important;
}

.color_white {
  color: var(--white) !important;
}

.bg_black {
  background-color: var(--black) !important;
}

.bg_blue {
  background-color: var(--blue) !important;
}

.bg_light_blue {
  background-color: var(--light-blue) !important;
}

.bg_white {
  background-color: var(--white) !important;
}

/* ================= COMMON CLASSES ======================= */
.object_fit_cover {
  object-fit: cover;
}

.main-heading {
  font-size: var(--font-2xl);
  font-weight: 500;
  font-family: gilroy-medium;
  color: var(--white);
}

.main_para {
  font-size: var(--font-sm);
  font-weight: 400;
  color: var(--white);
}

.common_btn {
  font-size: var(--font-sm);
  font-weight: 600 !important;
  color: var(--black);
  background-color: var(--white);
  font-family: "Open Sans", sans-serif;
  border-radius: 61px;
  border: 1px solid var(--white);
  padding: 8px 18px;
}

.cursor_pointer {
  cursor: pointer;
}

@media (min-width: 1200px) and (max-width: 1599.98px) {
  .modified_container {
    max-width: 1200px !important;
  }
}

/* navbar css */
.nav-list-items li {
  color: var(--color-white);
  font-weight: 400 !important;
  font-family: "Sarabun";
  font-size: var(--font-xsm);
  cursor: pointer;
}

@media (max-width: 1200px) {
  .nav-list-items li {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.nav-list-items li::after {
  content: "";
  display: block;
  width: 0%;
  height: 2px;
  background: white;
  background-repeat: no-repeat;
  background-size: contain;
  left: 0%;
  bottom: 0;
  -webkit-transition: 0.4s ease;
  -o-transition: 0.4s ease;
  transition: 0.4s ease;
}

.nav-list-items li:hover::after {
  width: 100%;
}

.navbar-wrapper {
  z-index: 10;
}

.navbar-overlay {
  position: fixed;
  width: 50%;
  height: 0%;
  z-index: 10;
  top: 0px;
  left: -800px;
  background: var(--blue);
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  border-radius: 0px 0px 100px 0px;
}

.active-nav-overlay .navbar-overlay {
  height: 100vh;
  top: 0;
  left: 0;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  border-radius: 0;
}

.navbar-overlay li {
  font-size: 4vw;
  color: var(--color-white) !important;
  font-weight: 400;
  position: relative;
  cursor: pointer;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  opacity: 1;
  list-style-type: none;
}

.navbar-overlay li::after {
  content: "";
  display: block;
  width: 0%;
  height: 0.5vh;
  background: var(--color-white);
  left: 50%;
  bottom: 0;
  border-radius: 30px;
}

.navbar-overlay li a:hover {
  opacity: 9 !important;
  color: #ffffff !important;
}

.navbar-overlay li:hover::after {
  width: 100%;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.h-screen {
  height: 100vh;
}

.fix-screen-height {
  height: 100vh;
  overflow: hidden;
}

/* HAMBURGER ICON STYLE  */
#nav-icon1 {
  width: 40px;
  height: 35px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

#nav-icon1 span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background-color: white;
  border-radius: 7px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

#nav-icon1 span:nth-child(1) {
  top: 4px;
}

#nav-icon1 span:nth-child(2) {
  top: 15px;
}

#nav-icon1 span:nth-child(3) {
  top: 27px;
}

.active-nav-overlay #nav-icon1 {
  z-index: 20;
}

.active-nav-overlay #nav-icon1 span:nth-child(1) {
  top: 16px;
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
}

.active-nav-overlay #nav-icon1 span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

.active-nav-overlay #nav-icon1 span:nth-child(3) {
  top: 16px;
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.navbar-link {
  position: relative;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.navbar-link::after {
  position: absolute;
  content: "";
  background-color: var(--color-light-green);
  width: 0%;
  height: 2px;
  bottom: -5px;
  left: 0;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border-radius: 5px;
}

.navbar-link:hover::after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  width: 100%;
}

.navbar-link:hover {
  color: var(--white);
}

.react_logo_adjust {
  position: absolute;
  top: 0;
  left: 0%;
}

.react_logo_adjust img {
  height: 125px;
  width: 600px;
}

@media (max-width: 1440px) {
  .react_logo_adjust img {
    height: 100px;
    width: 400px;
  }
}

@media (max-width: 991.98px) {
  .react_logo_adjust img {
    height: 100px;
    width: 300px;
  }
}

.min_vh_70 {
  min-height: 70vh;
}

@media (max-width: 1199.98px) {
  .min_vh_100 {
    min-height: 90vh;
  }
}

@media (max-width: 575.98px) {
  .min_vh_100 {
    min-height: 80vh;
  }
}

/*HERO CSS HERE-----------===========================*/
.header_btn {
  transition: all 0.3s ease-in-out;
}

.header_btn:hover {
  background-color: var(--blue);
  color: var(--white);
}

.hero_bg_img {
  background-image: url("./assets/images/png/hero-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  border-radius: 0px 0px 61px 61px;
  background-attachment: fixed;
  background-position-x: center;
}

/*SOLUTIONS TABS CLASSES HERE=======================================*/
.solutions_tabs {
  padding: 10px 17px;
  background: rgba(0, 19, 55, 0.9);
  border-radius: 61px;
}

.solutions_tabs_2 {
  padding: 10px 17px;
  background: transparent;
  border-radius: 61px;
}

.solutions_tabs_2:hover {
  background: rgba(0, 19, 55, 0.9);
  color: #ffffff !important;
}

/*BACK=============TO==================TOP===============CSS================START*/
.topscroll {
  position: fixed;
  border: 2px solid white !important;
  right: 25px;
  bottom: 45px;
  background: var(--black);
  height: 40px;
  width: 40px;
  z-index: 100;
  border-radius: 50%;
  -webkit-animation: up-down 2s ease-in-out infinite;
  animation: up-down 2s ease-in-out infinite;
  cursor: pointer;
}

.topscroll:hover {
  box-shadow: 0 3px 15px #000000;
}

@-webkit-keyframes up-down {
  50% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}

@keyframes up-down {
  50% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}

/* MEDIA QUERY START HERE================================== */

@media (max-width: 991.98px) {
  .hero_bg_img {
    background-position: 21%;
    background-attachment: unset;
  }
}

@media (max-width: 575.98px) {
  .hero_bg_img {
    min-height: 75vh;
  }
}
/* ==================== CORE CSS =================================== */

.health_ml_minus {
  width: 100%;
}
.health_p_pl {
  bottom: 0px;
  left: 5.3%;
}
.project_h {
  min-height: 830px;
}
.whitespace_nowrap {
  white-space: nowrap;
}
.streamline_after_line::after {
  bottom: -26px !important;
}
/* ==========DEFINE HERO SECITON CODE========== */
.hero_heading {
  font-size: 40px !important;
}
.nav_bg {
  background: var(--blue) !important;
}
.nav_bg2 {
  background: #ffff !important;
}
.border-radius-60 {
  border-radius: 60px !important;
}
/* ======DEFINE HEALTHCARE CSS====== */

.healthcare_bg {
  background-image: url("./assets/images/png/healthcare-new-img.png");
  background-size: cover;
  background-position: center;
  /* min-height: 250px; */
  border-radius: 80px !important;
}
.Project_bg {
  background-image: url("./assets/images/png/projectbg-new.png");
  background-size: cover;
  background-position: center;
  min-height: 250px;
  border-radius: 80px !important;
}
.panel_bg {
  background-image: url("./assets/images/png/panel-bg-new.png");
  background-size: cover;
  background-position: center;
  min-height: 250px;
  border-radius: 80px !important;
}
.solution_bg {
  /* background-image: url("./assets/images/png/solution_bg_img.png"); */
  background-size: cover;
  background-position: center;
  min-height: 250px;
  object-fit: cover;
  border-radius: 80px !important;
}
.artifical_bg {
  background-image: url("./assets/images/png/artifical-intiligency.png");
  background-size: cover;
  background-position: center;
  min-height: 250px;
}
.core_team_bg {
  background-image: url("./assets/images/png/core-team-img.png");
  background-size: cover;
  background-position: center;
  min-height: 250px;
}
.stream_line_bg_img {
  background-image: url("./assets/images/png/streamline_tab.png");
  background-size: cover;
  background-position: center;
  min-height: 250px;
}
.touch_bg {
  background-image: url("./assets/images/png/gettouch.png");
  background-size: cover;
  background-position: center;
  min-height: 250px;
  margin-top: 76px !important;
}
/* ======DEFINE STREAMLINE CSS====== */
.streamline_bg {
  margin-top: -247px;
  background-color: var(--blue) !important;
  border-radius: 80px !important;
  max-width: 1100px;
}
.streamline_heading {
  font-size: 24px !important;
}
.border_linear_gradient {
  border: 1.09px solid !important;
  border-image-source: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 2.21%,
    #ffffff 43.19%,
    rgba(255, 255, 255, 0) 90.82%
  ) !important;
  border-image-slice: 1 !important;
}
.streamline_img {
  max-width: 696px !important;
  transform: translate(6%, 63px);
  height: 780px !important;
  height: 104%;
  width: 132%;
}
/*======= DEFINE FOOTER CSS ======== */
.lh_142 {
  line-height: 142.52% !important;
}

.opacity_07 {
  opacity: 0.7 !important;
}
.footer_para {
  max-width: 437px;
}
.icon_hover {
  transition: 0.3s ease-in-out !important;
  opacity: 0.7 !important;
}

.icon_hover:hover {
  transform: translateY(-10%) !important;
  opacity: 1 !important;
}
.border_1 {
  border-top: 1px solid #f8f8ff !important;
  opacity: 0.5;
}
.text_line {
  position: relative;
  color: #fff !important;
  transition: width 0.3s ease-in-out;
  opacity: 0.7;
}
.text_line:hover {
  opacity: 1 !important;
}
.text_line::after {
  bottom: -2px;
  position: absolute;
  content: "";
  display: block;
  height: 0px;
  width: 0px;
  background: #f8f8ff;
  transition: width 0.3s ease-in-out;
  left: 50%;
}

.text_line:hover:after {
  position: absolute;
  content: "";
  height: 1px !important;
  width: 100% !important;
  left: 50%;
  transform: translateX(-50%);
  opacity: 1;
}
.features_content {
  transform: translateX(110px);
}
.line012 {
  width: 1px;
  height: 94px;
  background-color: var(--blue);
}
.line013 {
  width: 2px;
  height: 94px;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 2.21%,
    #ffffff 43.19%,
    rgba(255, 255, 255, 0) 90.82%
  );
  border: 1.09px solid;
  border-image-source: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 2.21%,
    #ffffff 43.19%,
    rgba(255, 255, 255, 0) 90.82%
  );
  border-image-slice: 1;
  /* transform: rotate(-90deg); */
}
.streamline_capsule_text {
  background: #d9d9d9;
  border-radius: 61px;
  max-width: 321px;
}
.enterprise_section {
  background: var(--light-black);
  border-radius: 80px;
}
.get_touch_btn {
  background-color: var(--dark-blue);
  border-radius: 90px;
  padding: 12px 52px;
  transition: all 0.3s ease-in-out;
}
.get_touch_btn:hover {
  transform: scale(0.98);
  opacity: 0.95;
}
.panel_parent {
  max-height: 846px;
}
.all_need_center_line {
  left: 0%;
  top: -6%;
  transform: translate(-50% -50%);
  height: 39%;
}
.qualitive_boxs p {
  font-size: var(--font-sm);
}
.aritficail_side_img {
  height: 400px;
  object-fit: cover;
}
.aritficail_side_positioning {
  top: 20%;
  left: 55%;
  transform: translate(-50% -50%);
}

@media (min-width: 992px) {
  .all_need_img {
    position: absolute;
    right: 0;
  }
  .all_need_center_line {
    left: 10%;
    top: 3%;
    transform: translate(-50% -50%);
    height: 39%;
  }
}
@media (min-width: 1200px) {
  .health_ml_minus {
    width: 106%;
    margin-left: -40px;
  }
  .row {
    width: 100%;
  }
  .col_xl_2 {
    width: 19.5% !important;
  }
  .features_heading {
    font-size: 60px !important;
  }
}
@media (min-width: 1600px) {
  .streamline_bg {
    margin-top: -30% !important;
    width: 100% !important;
    max-width: none !important;
  }
}
@media (max-width: 1399.98px) {
  .streamline_img {
    max-width: 600px !important;
    transform: translate(1%, 60px) !important;
    /* height: 700px !important; */
  }
  .contact_form_bg {
    padding: 69px 71px 68px 71px;
  }
}
@media (max-width: 1299.98px) {
  .streamline_img {
    width: 600px !important;
    height: 100% !important;
  }
  .streamline_bg {
    margin-top: -287px;
  }
}
@media (max-width: 1199.98px) {
  .streamline_bg {
    max-width: none !important;
    margin-top: -119px !important;
  }
  .streamline_img {
    width: 460px !important;
    height: 100% !important;
    /* transform: translate(6%, 40px) !important; */
  }
  .featurebg_img {
    top: 3%;
  }
  .panel_parent {
    max-height: 100%;
  }
  .priceing_bg_blue {
    height: 366px;
  }
  .hero_para_width {
    max-width: 391px;
  }
}
@media (max-width: 991.98px) {
  .hero_bg_img {
    min-height: 85vh !important;
  }
  .health_p_pl {
    left: 5.3%;
  }
  .footer_para {
    max-width: none !important;
  }

  .streamline_img {
    height: 100% !important;
    transform: translate(0, 50px) !important;
    max-width: 100% !important;
  }
  .streamline_bg {
    border-radius: 40px !important;
    /* max-width: none !important; */
    transform: translate(0%, 196px) !important;
    margin-top: -65% !important;
  }
  .hero_heading {
    font-size: 36px !important;
  }
  .panel_parent {
    max-height: 100%;
  }
  .priceing_bg_blue {
    height: 100%;
  }
  .popular_card_content2 {
    min-height: 100%;
    max-height: 100%;
  }
  .priceing_bg_blue2 {
    margin-top: 106px !important;
  }
  .mt_minus {
    margin-top: unset !important;
  }
  .hero_para_width {
    max-width: 415px;
  }
  .all_need_img {
    width: 100%;
  }
}
@media (max-width: 767.98px) {
  .health_p_pl {
    left: 5.3%;
  }

  .hero_bg_img {
    min-height: 670px !important;
  }
  .streamline_img {
    width: 100% !important;
    height: 100% !important;
    transform: translate(0, 40px) !important;
  }
  .streamline_bg {
    border-radius: 40px !important;
    /* max-width: none !important; */
    transform: translate(0%, 40px) !important;
    margin-top: -60% !important;
  }
  .Project_bg {
    min-height: 300px !important;
  }
  .panel_bg {
    min-height: 300px !important;
  }
  .solution_bg {
    min-height: 300px !important;
  }
  .artifical_bg {
    min-height: 300px !important;
  }
  .core_team_bg {
    min-height: 300px !important;
  }
  .stream_line_bg_img {
    min-height: 300px !important;
  }
  .priceing_bg_blue {
    padding-left: 16px;
    padding-right: 16px;
  }
  .features_content {
    transform: translateX(65px);
  }
}
@media (max-width: 575.98px) {
  .hero_bg_img {
    background-image: url("./assets/images/png/hero-bg-sm.png") !important;
    border-radius: 20px !important;
  }
  .healthcare_bg {
    /* border-radius: 30px !important; */
    min-height: 300px !important;
  }
  .streamline_bg {
    /* max-width: 396px !important; */
    margin: 0 auto;
    margin-top: -60% !important;
  }
  .streamline_img {
    height: 100% !important;
    transform: translate(0px, 50px) !important;
    width: 87% !important;
  }
  .features_content {
    transform: translateX(12px);
  }
  .streamline_capsule_text {
    padding-left: 24px;
    border-radius: 18px;
  }
  .enterprise_section {
    border-radius: 40px;
  }
  .solution_tab_img_max_w {
    max-width: 280px;
  }
  .priceing_bg_blue {
    padding-left: 8px;
    padding-right: 8px;
  }
  .priceing_bg_blue2 {
    margin-top: 48px !important;
  }
  .solution_tabs .nav-link {
    margin-left: 0px !important;
    margin-right: 8px !important;
    margin-bottom: 8px !important;
  }
  .features_box_pdig {
    padding: 30px 20px 24px 20px !important;
  }
  .priceing_bg_blue {
    border-radius: 50px;
  }
  .Project_bg {
    background-position: 20%;
  }
}
@media (max-width: 425.98px) {
  /* .streamline_img {
    max-width: 320px !important;
    max-height: 320px !important;
  } */
  .streamline_bg {
    /* max-width: 396px !important; */
    margin: 0 auto;
    margin-top: -70% !important;
  }
  .get_touch_btn {
    padding: 8px 40px;
  }
}
@media (max-width: 374.98px) {
  /* .streamline_img {
    max-width: 260px !important;
    max-height: 280px !important;
  } */
}
input::placeholder {
  color: white;
}
textarea::placeholder {
  color: white;
}
/* ========== INDEX CSS MERGE =========== */
.rounded_border_images {
  border-radius: 80px !important;
}

.rounded_border {
  border-radius: 80px !important;
}

/* ================================== Pharmaceuticalimg ================================ */
.Pharmaceuticalimg_img_positioning {
  left: -5.5%;
  top: 35% !important;
}

.box_center_line {
  left: 53%;
  top: 20%;
  transform: translate(-50% -50%);
}

.pharama_box_center_line {
  left: 57%;
  top: 20%;
  transform: translate(-50% -50%);
}
.draw_box_center_line {
  left: 6.5%;
  top: 5%;
  transform: translate(-50% -50%);
  height: 23%;
}

/* ================================== Pharmaceuticalimg END ================================ */
/* ================================== SOLUTION ================================ */
.max_w_participant {
  max-width: 760px;
}

.solution_tab_positioning {
  top: -10%;
  left: 62%;
  transform: translate(-50% -50%);
}

.solution_tab_img_max_w {
  max-width: 512px;
}

/* ================================== SOLUTION END ================================ */
/* ================================== TABS MODIFY ================================== */
.nav-tabs {
  border: unset !important;
}

.nav-link {
  /* font-family: "Open Sans" !important; */
  font-weight: 300 !important;
  font-size: 16px !important;
  color: #000 !important;
  padding: 10px 17px !important;
  margin-left: 20px !important;
}

/* .solution_tabs .nav-link:hover {
  background: var(--blue) !important;
  border-radius: 61px !important;
  padding: 10px 17px !important;
  font-weight: 400;
  color: #e0e0e0 !important;
  margin-left: 20px !important;
} */

.solution_tabs .nav-link {
  margin-left: 20px !important;
  padding: 10px 28px !important;
}
.solution_tabs .nav-link:hover {
  background: var(--blue) !important;
  font-weight: 400;
  color: #e0e0e0 !important;
}

.nav-tabs .nav-link {
  background: transparent !important;
  border-radius: 61px !important;
  padding: 10px 28px !important;
  font-weight: 400;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background: var(--blue) !important;
  border-radius: 61px !important;
  padding: 10px 28px !important;
  font-weight: 400;
  color: #e0e0e0 !important;
}

.nav-tabs .nav-link.active {
  border: none !important;
  /* margin-left: 20px !important; */
}

.tabs_left_padding .nav-link.active {
  border-radius: 18px 18px 0px 0px !important;
  /* transform: translateY(1px); */
}

.tabs_left_padding {
  padding-left: 10% !important;
}

.solution_tabs .nav-link {
  margin-bottom: unset !important;
  background: transparent !important;
  border: none !important;
  border-top-left-radius: unset !important;
  border-top-right-radius: unset !important;
  border-radius: 61px !important;
}

.tabs_left_padding .nav-link {
  margin-bottom: unset !important;
  background: transparent !important;
  border: unset !important;
  border-top-left-radius: unset !important;
  border-top-right-radius: unset !important;
  border-radius: 18px 18px 0px 0px !important;
  font-size: var(--font-lg) !important;
  padding: 10px 28px !important;
}

.max_w_fieldwork {
  max-width: 670px;
}

/* ================================== TABS MODIFY END ================================== */
/* ================================== CORE TEAM  ================================== */
.nav-pills .nav-link.active {
  background: #ffff !important;
  color: #000 !important;
  border-radius: 90px;
  width: 80% !important;
  padding: 12px 20px !important;
}

.nav-pills .nav-link:hover.active {
  background: #ffff !important;
  color: #000 !important;
  border-radius: 90px;
  opacity: 1;
  width: 80% !important;
  padding: 12px 20px !important;
}

.nav-pills .nav-link:hover {
  background: #e6e9eda4 !important;
  color: #fff !important;
  border-radius: 90px;
  opacity: 0.8;
  width: 80% !important;
  padding: 12px 20px !important;
}

.tab_span_translate {
  transform: translateY(10px);
}

.core_team_center_line {
  left: 35%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.nav-pills .nav-link {
  border-radius: 90px !important;
  width: 80% !important;
  padding: 12px 20px !important;
}

.webkit_scrollbar_core_team::-webkit-scrollbar {
  background-color: transparent !important;
  height: 2px;
}

.webkit_scrollbar_core_team::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 10px;
}

/* Handle on hover */
.webkit_scrollbar_core_team::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}

/* ================================== CORE TEAM END ================================== */
/* ================================== JOIN OUR PANNEL ================================ */
.join_btn {
  border-radius: 61px;
  padding: 8px 25px;
  background: #fff;
  border: 1px solid #fff;
  transition: all 0.3s ease-in-out;
}

.join_btn:hover {
  background: transparent;
  color: #fff !important;
}

.input_modified {
  background: transparent;
  border: transparent;
  border-bottom: 0.5px solid #fff;
  padding-bottom: 9px;
  color: #fff !important;
  opacity: 0.7;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
}

.input_modified:hover {
  outline: none;
}

.input_modified::placeholder {
  color: #fff !important;
  opacity: 0.7;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

input:focus-visible {
  outline: none;
}

.pannel_img {
  position: absolute;
  top: 56%;
  left: 5%;
}

/* ================================== JOIN OUR PANNEL END ================================ */
/* ================================== ARTIFICAL INIELLIGENCE ================================ */
.rounded_border_slider_top {
  border-radius: 80px 80px 0px 0px;
}

.slider_content_max_w {
  max-width: 407px;
}

.slick-prev:before {
  content: url("./assets/images/svg/slider-prev-img.svg") !important;
  color: #000 !important;
  opacity: 100 !important;
  opacity: 0.8 !important;
  transition: all 0.3s ease-in-out;
}

.slick-prev:hover::before {
  opacity: 1 !important;
}

.slick-next:before {
  content: url("./assets/images/svg/slider-next-img.svg") !important;
  color: #000 !important;
  opacity: 0.8 !important;
  transition: all 0.3s ease-in-out;
}

.slick-next:hover::before {
  opacity: 1 !important;
}

.slick-prev {
  top: 88% !important;
  left: 35% !important;
  z-index: 1 !important;
}

.slick-next {
  top: 88% !important;
  left: 50% !important;
  z-index: 1 !important;
}

/* ================================== ARTIFICAL INIELLIGENCE END ================================ */
/* ================================== PROJECT JOURNEY ================================ */
.requirment_height {
  height: 88%;
}

.field_work_height {
  height: 92%;
}

.project_closer_height {
  height: 102%;
}

/* ================================== PROJECT JOURNEY END ================================ */
/* ================================== STREAM LINE TAB ================================ */
.stream_line_tab_positioning {
  top: 38% !important;
  left: 60%;
  transform: translate(-50% -50%);
}

.features_child_max_width_h {
  max-width: 350px;
}

.features_child_max_width_p {
  max-width: 300px;
}

.streamline_after_line1::after {
  content: "";
  position: absolute;
  width: 64%;
  height: 0.3px;
  border: 0.3px solid #0a6fff;
  opacity: 0.5;
  bottom: -10px;
  left: 0;
}

.streamline_after_line2::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0.3px;
  border: 0.3px solid #0a6fff;
  opacity: 0.5;
  bottom: -10px;
  left: 0;
}

.streamline_after_line3::after {
  content: "";
  position: absolute;
  width: 153%;
  height: 0.3px;
  border: 0.3px solid #0a6fff;
  opacity: 0.5;
  bottom: -10px;
  left: 0;
}

.opacity_05 {
  opacity: 0.05;
}

.features_tab_max_width_a {
  max-width: 285px;
}

.features_tab_max_width_b {
  max-width: 320px;
}

.features_tab_max_width_one_point_contact {
  max-width: 300px;
}

.gap_30 {
  gap: 6px;
}

/* ================================== STREAM LINE TAB END ================================ */
.hero_para_width {
  max-width: 450px;
}

.power_img {
  max-width: 284px;
}
/* ================================== MEDIA QUERY ============================== */
@media (max-width: 1399.98px) {
  .img_max_w {
    max-width: 580px;
  }

  .Pharmaceuticalimg_img_positioning {
    left: -5%;
    top: 45%;
  }

  .gap_30 {
    gap: 30px;
  }

  .map_img {
    width: 100%;
  }

  .streamline_after_line3::after {
    width: 127%;
  }

  .box_center_line {
    left: 55%;
  }
}

@media (max-width: 1199.98px) {
  .Pharmaceuticalimg_img_positioning {
    left: -4%;
    top: 45%;
  }

  .pannel_img {
    position: absolute;
    top: 64%;
    left: 0%;
  }

  .img_max_w {
    max-width: 520px;
  }

  .slick-prev {
    top: 88% !important;
    left: 33% !important;
  }

  .slick-next {
    top: 88% !important;
    left: 53% !important;
  }

  .solution_tab_positioning {
    top: -11%;
    left: 61%;
  }

  .max_w_fieldwork {
    max-width: 570px;
  }

  .max_w_participant {
    max-width: 570px;
  }

  .core_team_center_line {
    left: 27%;
  }

  .streamline_after_line::after {
    width: 80%;
    bottom: -20%;
  }

  .nav-pills .nav-link.active {
    width: 96% !important;
  }

  .nav-pills .nav-link:hover.active {
    width: 96% !important;
  }

  .nav-pills .nav-link:hover {
    width: 96% !important;
  }

  .nav-pills .nav-link {
    width: 96% !important;
  }

  .contact_form_bg {
    padding: 69px 60px 60px 71px !important;
  }

  .streamline_after_line3::after {
    width: 110%;
  }

  .footer_logo {
    width: 20% !important;
  }

  .box_center_line {
    left: 57%;
  }
}

@media (max-width: 991.98px) {
  .img_max_w {
    max-width: 580px;
  }
  .popular_card_content2 {
    min-height: 200px !important;
    /* max-height: 410.2px; */
    overflow: auto;
    padding-bottom: 280px !important;
  }

  .box_center_line {
    left: 47%;
    top: 47%;
    transform: translateX(-50%) !important;
  }

  .rounded_border {
    border-radius: 60px !important;
  }

  .rounded_border_images {
    border-radius: 50px !important;
  }

  .rounded_border_slider_top {
    border-radius: 50px 50px 0px 0px;
  }

  .slick-prev {
    left: 35% !important;
  }

  .slick-next {
    left: 50% !important;
  }

  .nav-pills .nav-link.active {
    width: 100% !important;
  }

  .core_team_center_line_mobile {
    left: 47%;
    top: 66%;
    transform: translate(-50%, -50%);
  }

  .participant_img_max_width {
    max-width: 430px;
  }

  .building_box {
    padding: 40px 40px 25px 40px !important;
  }

  .streamline_after_line3::after {
    width: 100%;
    bottom: 0;
  }

  .streamline_after_line2::after {
    width: 100%;
    bottom: 0;
  }

  .streamline_after_line1::after {
    width: 100%;
    bottom: 0;
  }

  .max_w_participant {
    margin: auto;
  }

  .features_box_pdig {
    padding: 48px 60px 30px 60px !important;
  }
  .power_img {
    max-width: 264px;
  }
}

@media (max-width: 767.98px) {
  .img_max_w {
    max-width: 420px;
  }
  .box_center_line {
    top: 41.5%;
  }

  .rounded_border {
    border-radius: 40px !important;
  }

  .rounded_border_images {
    border-radius: 30px !important;
  }

  .join_btn {
    border-radius: 40px;
  }

  .rounded_border_slider_top {
    border-radius: 30px 30px 0px 0px;
  }

  .nav-link {
    margin-left: 0px !important;
  }
  /* 
  .nav-tabs .nav-link.active {
    margin-left: 0px !important;
  } */

  .streamline_after_line::after {
    width: 80%;
    bottom: -10%;
  }

  .core_team_center_line_mobile {
    top: 70%;
  }

  .qualitive_boxs {
    width: 180px !important;
  }

  .contact_form_bg {
    padding: 37px 17px 40px 17px !important;
  }

  .features_box_pdig {
    padding: 30px 25px 24px 25px !important;
  }

  .footer_logo {
    width: 130px !important;
  }
}

@media (max-width: 575.98px) {
  .border_rounded_custom_all_images_Mobile {
    border-radius: 24px !important;
  }
  .rounded_border {
    border-radius: 15px !important;
  }
  .tabs_left_padding {
    padding-left: 0 !important;
  }
  .img_max_w {
    max-width: 300px;
  }
  .popular_card_content2 {
    min-height: 500px !important;
    /* max-height: 410.2px; */
    overflow: auto;
    padding-bottom: 50px !important;
  }
  .box_center_line {
    left: 46%;
    top: 36.5%;
  }

  .slick-prev {
    left: 30% !important;
  }

  .slick-next {
    left: 55% !important;
  }

  .participant_img_max_width {
    max-width: unset;
  }

  .building_box {
    padding: 25px 25px 0px 25px !important;
  }

  .contact_form_bg {
    padding: 37px 17px 40px 17px !important;
  }

  .gap_30 {
    gap: 0px;
  }
  .qualitive_boxs {
    width: 100% !important;
    padding: 23px 6px 27px 6px !important;
    min-width: 124px;
  }
  .qualitive_boxs p {
    font-size: 13px !important;
  }
  .qualitive_boxs svg {
    max-width: 48px;
    max-height: 48px;
  }
  .power_img {
    max-width: 224px;
  }
}

/* ================================== MEDIA QUERY END ============================== */

/* =========================UPDATES================================= */
.qualitive_boxs {
  background: #ffffff;
  border: 1.22172px solid rgba(0, 0, 0, 0.15);
  border-radius: 19.5475px;
  padding: 23px 17px 27px 18px;
  transition: all 0.3s linear;
  color: #000;
  width: 204px;
}

.qualitive_boxs:hover {
  background: #4f6789;
  box-shadow: 0px 4px 23px rgba(79, 103, 137, 0.22);
  border-radius: 19.5475px;
  /* padding: 23px 17px 27px 17px; */
  color: white;
}

.qualitive_boxs:hover svg .fill_white {
  fill: white !important;
  opacity: 1;
}

.max_width_1140 {
  max-width: 1140px;
}

.tab_bottom_border {
  border-bottom: 0.3px solid #0a6fff !important;
}

.building_box {
  padding: 77px 0 80px 71px;
}

.p_32 {
  padding: 32px;
}

.sector_border {
  opacity: 0.7;
  border: 1px solid #ffffff;
  border-radius: 100px;
  padding: 9px 7px 9px 31px;
}

.contact_form_bg {
  padding: 69px 72px 75px 71px;
}

.input_box {
  border: 1px solid rgba(255, 255, 255, 0.28);
  border-radius: 4px;
  background: transparent;
  padding: 17px 20px;
}

.send_btn {
  border-radius: 61px;
  padding: 12px 68px;
  background: #fff;
  border: 1px solid #fff;
  transition: all 0.3s ease-in-out;
}

.send_btn:hover {
  background: transparent;
  color: #fff !important;
}

.cost_saving_max_w {
  max-width: 750px;
}

.features_box_pdig {
  padding: 14px 60px 24px 60px;
}

.before_data_line {
  position: relative;
}

.before_data_line::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 70%;
  height: 1px;
  background: #000;
}

.feature_tab_img {
  width: 100%;
}

.streamline_box {
  background: #ffffff;
  border-radius: 21px 50px 50px 0px;
  padding: 11px 25px;
  margin-left: -8px;
  z-index: 0;
}

.z_index_one {
  z-index: 1;
}

.z_index_zero {
  z-index: 0;
}

.my-border {
  border: 1px solid #00000026;
  border-radius: 21px 50px 50px 0px;
  background: none;
}

.featurebg_img {
  top: 5%;
  width: 100%;
  min-height: 400px;
}

.popular_bg:hover .popular_card_content .submit_btn {
  background: #1a2b4b;
  color: #ffff;
}
.popular_bg:hover .popular_card_content2 .submit_btn {
  background: #1a2b4b;
  color: #ffff;
}

.fold_populer_box {
  background-image: url("./assets/images/png/popular_bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 19px 17px;
  z-index: 1;
  position: relative;
}

.popular_card_content {
  min-height: 390.2px;
  max-height: 410.2px;
  border: 1px solid #00000026;
  padding: 20px 17px 39px 17px;
  transition: all 0.3s linear;
  height: 100%;
  background: #fff;
  margin-top: -50px;
  z-index: 0;
  box-shadow: 0px 6px 15px 0px #81818140;
}
.popular_card_content2 {
  min-height: 460.2px;
  max-height: 410.2px;
  border: 1px solid #00000026;
  /* padding: 20px 17px 39px 17px; */
  padding: 20px 21px 39px 23px;
  transition: all 0.3s linear;
  height: 100%;
  background: #fff;
  margin-top: -50px;
  z-index: 0;
  box-shadow: 0px 6px 15px 0px #81818140;
}

.submit_btn {
  padding: 11px 20px;
  background: white;
  border: 1px solid #1a2b4b;
  border-radius: 90px;
  transition: all 0.3s linear;
  color: #1a2b4b;
}

.coming_soon_heading {
  font-size: 110px;
}

.coming_soon_cal_hight {
  min-height: calc(100vh - 43px);
}

.footer_logo {
  width: 24%;
}

.priceing_bg_blue {
  background: #001337;
  border-radius: 80px;
  height: 386px;
}
.mt_minus {
  margin-top: -60px !important ;
}
.mt_minus2 {
  margin-top: -115px !important ;
}
.priceing_bg_blue2 {
  margin-top: 286px !important;
}
.mt_6rem {
  margin-top: 6rem;
}
@media (min-width: 992px) {
  .feature_tab_img {
    transform: translateY(35px);
    width: 40%;
    position: absolute;
  }
}
@media (max-width: 1399.98px) {
  .popular_card_content {
    min-height: 390px;
    max-height: 390px;
  }
}
@media (max-width: 1199.98px) {
  .popular_bg {
    background-size: cover !important;
    /* max-width: 363px !important; */
  }
  /* 
  .mb_100 {
    margin-bottom: 0px;
  } */
}
@media (max-width: 991.98px) {
  .coming_soon_heading {
    font-size: 42px !important;
  }
  .popular_card_content {
    min-height: 470px;
    max-height: 470px;
  }

  .mt_6rem {
    margin-top: 4rem;
  }
  .mt_minus2 {
    margin-top: 32px !important;
  }
}
@media (max-width: 768.98px) {
  .solution_tabs .nav-link {
    margin-left: 0px !important;
  }
  .dataservice_sec ul,
  .fieldwork_sec .tabs_left_padding {
    display: flex !important;
    justify-content: center !important;
  }
  .popular_card_content {
    min-height: 370px;
    max-height: 100%;
  }
  .topscroll {
    height: 35px;
    right: 10px;
    bottom: 15px;
    width: 35px;
  }

  .nav-tabs .nav-link.active,
  .nav-tabs .nav-link {
    font-size: 16px !important;
  }
  html {
    scroll-behavior: unset !important;
  }
}
@media (min-width: 992px) {
  .contact_positioning {
    position: absolute;
    bottom: -5%;
    right: -3%;
    transform: translate(-50% -50%);
  }
}
